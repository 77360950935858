<template>
  <div id="header" class="header" style="font-size:14px;">
    <!-- Header First -->
    <div :class="[onTop ? 'topLineBeforeScroll' : 'topLineAfterScroll']">
      <div>
        <!-- contact -->
        <div class="d-flex">
          <!-- Email -->
          <div class="d-flex align-items-center mr-2">
            <el-tooltip
              :enterable="false"
              :content="$t('landingPage.contact.email')"
              placement="top"
              popper-class="tooltipColor"
            >
              <div class="d-flex align-items-center">
                <div class="input-icon icon-email"></div>

                <span>toefl@mock100.com</span>
              </div>
            </el-tooltip>
          </div>
          <!-- <div class="d-flex align-items-center mr-2">
            <el-tooltip
              :enterable="false"
              content="Twitter"
              placement="top"
              popper-class="tooltipColor"
            >
              <div class="d-flex align-items-center">
                <span class="icon">
                  <i class="fab fa-twitter"></i>
                </span>
              
                >
              </div>
            </el-tooltip>
          </div> -->
          <!-- Telegram -->
          <!-- <div class="d-flex align-items-center mr-2">
            <el-tooltip
              :enterable="false"
              content="Telegram"
              placement="top"
              popper-class="tooltipColor"
            >
              <div class="d-flex align-items-center">
                <span class="icon">
                  <i class="fab fa-telegram"></i>
                </span>
                <a target="_blank" href="">MOCK 100</a>
              </div>
            </el-tooltip>
          </div> -->
          <div class="d-flex ml-auto pr-3">
            <div class="dropdown pr-2">
              <div class="dropdown-toggle langOnTop" data-toggle="dropdown">
                Language: {{ lang.title }}
              </div>
              <div
                class="dropdown-menu"
                style="z-index:2001; font-size:14px;"
                v-if="languageList1.length > 0"
              >
                <div style="cursor: pointer">
                  <div
                    class="dropdown-item"
                    @click="handleLanguageChange(languageList1[0])"
                  >
                    {{ languageList1[0].title }}
                  </div>
                  <div
                    class="dropdown-item"
                    @click="handleLanguageChange(languageList1[1])"
                  >
                    {{ languageList1[1].title }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      :class="[
        'navWrapperBefore',
        onTop ? 'menuBeforeScroll' : 'menuAfterScroll',
        'toefl-logo'
      ]"
    >
      <div class="d-md-inline-block">
        <div class="logo">
          <router-link
            :to="{ name: 'LandingPage' }"
            style="text-decoration:none"
            @click="closeClick"
          >
            <h2>
              MOCK 100
            </h2>
          </router-link>
          <el-dropdown trigger="click">
            <span class="el-dropdown-link toefl-chevron-down">
              <span><i class="fas fa-chevron-down"/></span>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <b><router-link :to="{ name: 'LandingPage' }">TOEFL</router-link></b>
              </el-dropdown-item>
              <el-dropdown-item>
                <b
                  ><a
                    href=""
                    style="color:#FF770C !important;"
                    >IELTS
                  </a></b
                >
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div
        id="menu"
        :class="{ active: isActive }"
        class="d-md-inline-block float-md-right"
      >
        <el-menu
          mode="horizontal"
          router
          :text-color="themeColor"
          :active-text-color="themeColor"
        >
          <el-menu-item
            class="mr-0"
            :class="`item${index + 1}`"
            v-for="(menu, index) in menuList"
            :key="index"
            :route="menu.route"
            :index="String(index + 1)"
          >
            <router-link style="text-decoration: none" :to="menu.route">
              <div
                :class="{ menuPadding: !isLogin }"
                class="onHover pa-0 pr-1 headerMenuItemText"
              >
                {{ $t(menu.i18nKey) }}
              </div>
            </router-link>
          </el-menu-item>
          <el-submenu index="6-2" class="resource mr-0 el-menu-item">
            <template slot="title">
              <span class="onHover">
                {{ $t("header.classroom") }}
              </span>
            </template>
            <el-menu-item v-for="(menu, index) in resourceList" :key="index">
              <router-link
                :to="{ name: menu.route.name }"
                class="menuItem subItem"
                style="display:block"
              >
                {{ $t(menu.i18nKey) }}
              </router-link>
            </el-menu-item>
          </el-submenu>
          <el-menu-item class="mr-0 item6">
            <router-link
              style="text-decoration: none"
              :to="{ name: 'ToeflPlans' }"
            >
              <div class="onHover pa-0 pr-1">
                {{ $t("header.plans") }}
              </div>
            </router-link>
          </el-menu-item>
          <!-- user dropdown -->
          <el-submenu
            index="6-1"
            v-if="isLogin"
            class="userSection el-menu-item"
          >
            <template slot="title">
              <span v-if="getProfile.first_name"
                >Hi, {{ getProfile.first_name }}</span
              >
              <span v-else>Hi, {{ getProfile.username }}</span>
              <span v-if="getIsPro" class="proIcon">
                <i class="fas fa-crown" />
              </span>
            </template>
            <el-menu-item v-for="(elem, index) in loginList" :key="index">
              <router-link
                :to="{ name: elem.routeName }"
                class="menuItem"
                style="display:block"
              >
                {{ $t(elem.title) }}
              </router-link>
            </el-menu-item>
            <template v-if="isRoleAdmin()">
              <el-menu-item v-for="elem in adminList" :key="elem.title">
                <router-link
                  :to="{ name: elem.routeName }"
                  class="menuItem"
                  style="display:block"
                >
                  {{ $t(elem.title).toUpperCase() }}
                </router-link>
              </el-menu-item>
            </template>
            <el-menu-item @click="handleLogout">
              <p class="menuItem">{{ $t("header.logout") }}</p>
            </el-menu-item>
          </el-submenu>
          <el-menu-item v-else @click="goToRoute('Login')">
            <el-button size="small" type="" :style="{backgroundColor: '#a1426d',color:'#fff',border:0}">{{
              $t("header.login")
            }}</el-button>
          </el-menu-item>
        </el-menu>
      </div>
      <div id="toggle" @click="toggleClick" class="mr-4">
        <div class="span" id="top" :class="{ active: isActive }"></div>
        <div class="span" id="middle" :class="{ active: isActive }"></div>
        <div class="span" id="bottom" :class="{ active: isActive }"></div>
      </div>
    </div>
    <el-drawer
      :visible.sync="isActive"
      direction="rtl"
      :modal="false"
      size="360px"
    >
      <h4 slot="title">
        <template v-if="isLogin">
          <span v-if="getProfile.first_name">
            Hi, {{ getProfile.first_name }}</span
          >
          <span v-else>Hi, {{ getProfile.username }}</span>
          <span v-if="getIsPro" class="proIcon">
            <i class="fas fa-crown" />
          </span>
        </template>
      </h4>
      <div class="accordion listParent" id="accordionExample">
        <div class="card">
          <div class="card-header" id="headingOne" v-if="isLogin">
            <h2
              class="mb-0"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-controls="collapseOne"
              @click="() => toggleCollapse('collapseOne')"
            >
              <div class="collapse-item">
                <span style="display:inline-block;width:25px">
                  <i class="fas fa-user"></i>
                </span>
                Account
              </div>
              <small
                v-if="isShow.collapseOne"
                key="up"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-controls="collapseOne"
              >
                <i class="fas fa-chevron-up" />
              </small>
              <small
                v-else
                key="down"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-controls="collapseOne"
              >
                <i class="fas fa-chevron-down" />
              </small>
            </h2>
          </div>

          <div
            class="card-header"
            id="headingOne"
            v-if="!isLogin"
            @click="goToRoute('Login')"
          >
            <h2
              class="mb-0"
              data-toggle="collapse"
              data-target="#collapseZero"
              aria-expanded="true"
              aria-controls="collapseZero"
            >
              <div class="collapse-item">
                <span style="display:inline-block;width:25px">
                  <i class="fas fa-sign-in-alt"></i>
                </span>
                <span>{{ $t("header.login") }}</span>
              </div>
            </h2>
          </div>

          <div
            id="collapseOne"
            class="collapse"
            aria-labelledby="headingOne"
            data-parent="#accordionExample"
          >
            <div class="card-body" v-if="isLogin">
              <div
                class="list onHover"
                v-for="(elem, index) in loginList"
                :key="index"
                @click="closeClick"
              >
                <router-link class="listText" :to="{ name: elem.routeName }">
                  <span style="display:inline-block;width:25px">
                    <i :class="elem.icon"></i>
                  </span>
                  {{ $t(elem.title) }}
                </router-link>
              </div>
              <template v-if="isRoleAdmin()">
                <div
                  class="list onHover"
                  v-for="elem in adminList"
                  :key="elem.routeName"
                  @click="closeClick"
                >
                  <router-link class="listText" :to="{ name: elem.routeName }">
                    <span style="display:inline-block;width:25px">
                      <i :class="elem.icon"></i>
                    </span>
                    {{ $t(elem.title) }}
                  </router-link>
                </div>
              </template>
              <div class="list onHover">
                <span class="listText" @click="handleLogout">
                  <span style="display:inline-block;width:25px">
                    <i class="fas fa-sign-out-alt"></i>
                  </span>
                  {{ $t("header.logout") }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingTwo">
            <h2
              class="mb-0"
              data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="true"
              aria-controls="collapseTwo"
              @click="() => toggleCollapse('collapseTwo')"
            >
              <div class="collapse-item">
                <span style="display:inline-block;width:25px">
                  <i class="fas fa-book"></i>
                </span>
                TOEFL
              </div>
              <small
                v-if="isShow.collapseTwo"
                key="up"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseTwo"
              >
                <i class="fas fa-chevron-up" />
              </small>
              <small
                v-else
                key="down"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseTwo"
              >
                <i class="fas fa-chevron-down" />
              </small>
            </h2>
          </div>
          <div
            id="collapseTwo"
            class="collapse show"
            aria-labelledby="headingTwo"
            data-parent="#accordionExample"
          >
            <div class="card-body">
              <div
                class="list onHover"
                v-for="(menu, index) in [...menuList, ...resourceList]"
                :key="index"
                @click="closeClick"
              >
                <router-link style="text-decoration: none" :to="menu.route">
                  <div
                    :class="{ menuPadding: !isLogin }"
                    class="onHover pa-0 pr-1"
                  >
                    <span style="display:inline-block;width:25px">
                      <i :class="menu.icon"></i>
                    </span>
                    {{ $t(menu.i18nKey) }}
                  </div>
                </router-link>
              </div>
              <div class="list onHover" @click="closeClick">
                <router-link
                  style="text-decoration: none"
                  :to="{ name: 'ToeflPlans' }"
                >
                  <div class="onHover pa-0 pr-1">
                    <span style="display:inline-block;width:25px">
                      <i class="fas fa-crown"></i>
                    </span>
                    {{ $t("header.plans") }}
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingThree">
            <h2
              class="mb-0"
              data-toggle="collapse"
              data-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
              @click="() => toggleCollapse('collapseThree')"
            >
              <div class="collapse-item">
                <span style="display:inline-block;width:25px">
                  <i class="fas fa-globe"></i>
                </span>
                Language
              </div>
              <small
                v-if="isShow.collapseThree"
                key="up"
                data-toggle="collapse"
                data-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                <i class="fas fa-chevron-up" />
              </small>
              <small
                v-else
                key="down"
                data-toggle="collapse"
                data-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                <i class="fas fa-chevron-down" />
              </small>
            </h2>
          </div>
          <div
            id="collapseThree"
            class="collapse"
            aria-labelledby="headingThree"
            data-parent="#accordionExample"
          >
            <div class="card-body">
              <div class="list-active list onHover">
                <span>
                  <span style="display:inline-block;width:25px">
                    <i class="fas fa-check-square"></i>
                  </span>
                </span>
                {{ lang.title }}
              </div>
              <div
                class="list onHover"
                v-for="(language, index) in languageList1"
                :key="index"
              >
                <span class="listText" @click="handleLanguageChange(language)">
                  <span style="display:inline-block;width:25px"></span>
                  {{ language.title }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import userApi from "@/apis/user";
import roleMixin from "@/mixins/role";

export default {
  components: {},

  mixins: [roleMixin],

  props: [],
  data() {
    return {
      menuList: [
        {
          icon: "fas fa-dumbbell",
          route: {
            name: "LandingPage"
          },
          i18nKey: "courses.practice"
        },
        {
          icon: "fas fa-font",
          route: {
            name: "Flashcards"
          },
          i18nKey: "header.flashcards"
        }
      ],
      resourceList: [
        // {
        //   icon: "fas fa-chalkboard-teacher",
        //   route: {
        //     name: "Lessons"
        //   },
        //   i18nKey: "header.lessons"
        // },
        {
          icon: "fas fa-hands",
          route: {
            name: "MyMaterials"
          },
          i18nKey: "header.materials"
        }
      ],
      loginList: [
        {
          icon: "fas fa-id-badge",
          title: "header.profile",
          routeName: "Profile"
        },
        {
          icon: "fas fa-donate",
          title: "header.balance",
          routeName: "ToeflProfileBalance"
        }
      ],
      adminList: [
        {
          icon: "fas fa-file-invoice-dollar",
          title: "header.payments",
          routeName: "ToeflPayments"
        },
        {
          icon: "fas fa-users",
          title: "header.students",
          routeName: "ToeflStudents"
        }
      ],
      languageList: [
        {
          value: "ZH-CN",
          title: "简体中文"
        },
        {
          value: "ZH-TW",
          title: "繁體中文"
        },
        {
          value: "EN-US",
          title: "English"
        }
      ],
      languageList1: [
        {
          value: "ZH-CN",
          title: "简体中文"
        },
        {
          value: "ZH-TW",
          title: "繁體中文"
        },
        {
          value: "EN-US",
          title: "English"
        }
      ],
      languageList2: [
        {
          value: "ZH-CN",
          title: "简体中文"
        },
        {
          value: "ZH-TW",
          title: "繁體中文"
        },
        {
          value: "EN-US",
          title: "English"
        }
      ],
      isActive: false,
      onTop: true,
      wechatQRcode1: require("@/assets/qrcode/ivywaychina_QR.webp"),
      wechatQRcode2: require("@/assets/qrcode/TW_WeChat_QR.webp"),
      wechatQRcode3: require("@/assets/qrcode/ivywayedu_QR.webp"),
      facebookQRcode: require("@/assets/qrcode/ivywayfacebook_QR.webp"),
      lineQRcode: require("@/assets/qrcode/line_QR.jpg"),
      isShow: {
        collapseOne: false,
        collapseTwo: true,
        collapseThree: false
      }
    };
  },
  computed: {
    ...mapState("user", ["token", "lang"]),
    ...mapGetters("user", ["token", "getProfile", "getIsPro"]),
    isLogin() {
      // TODO: 應該還要檢查 token 是否合法？
      return this.token !== "";
    }
  },
  watch: {
    getProfile(val) {
      if (val.lang === null) {
        this.autoLang();
      } else {
        this.currCountryCode = this.getProfile.lang.toUpperCase();
        this.changeLangDefault();
      }
    }
  },
  async mounted() {
    this.updateLangOrder(this.lang.title);
    if (this.isLogin && !this.getProfile.length) {
      await this.$store.dispatch("user/getProfile");
    }
    if (JSON.stringify(this.getProfile) === "{}") {
      this.autoLang();
    } else {
      if (this.getProfile.lang === null) {
        this.autoLang();
      } else {
        this.currCountryCode = this.getProfile.lang.toUpperCase();
        this.changeLangDefault();
      }
    }
    // const oScript = document.createElement("script");
    // oScript.async = true;
    // oScript.defer = true;
    // oScript.crossorigin = "anonymous";
    // oScript.nonce = "2vOEv1RY";
    // switch (this.lang.value) {
    //   case "EN-US":
    //     oScript.src =
    //       "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v11.0&appId=830214810896821&autoLogAppEvents=1";
    //     break;
    //   case "ZH-CN":
    //     oScript.src =
    //       "https://connect.facebook.net/zh_CN/sdk.js#xfbml=1&version=v11.0&appId=830214810896821&autoLogAppEvents=1";
    //     break;
    //   default:
    //     oScript.src =
    //       "https://connect.facebook.net/zh_TW/sdk.js#xfbml=1&version=v11.0&appId=830214810896821&autoLogAppEvents=1";
    // }

    // document.body.appendChild(oScript);
    this.startObserveWindowWidth();
  },

  methods: {
    ...mapActions("user", ["changeLang", "logout"]),
    goToRoute(route) {
      this.closeClick();
      if (this.$route.name !== route) {
        this.$router.push({
          name: route
        });
      }
    },
    closeClick() {
      document.documentElement.style.overflow = "overlay";
      this.isActive = false;
      this.showLanguages = false;
      // 只要每次關閉 list 就重置所有 collapse 為隱藏狀態
      if (document.getElementById("collapseOne")) {
        document.getElementById("collapseOne").classList.remove("show");
      }
      if (document.getElementById("collapseTwo")) {
        document.getElementById("collapseTwo").classList.add("show");
      }
      if (document.getElementById("collapseThree")) {
        document.getElementById("collapseThree").classList.remove("show");
      }
    },
    toggleClick() {
      const currOverflow = document.documentElement.style.overflow;
      if (currOverflow === "hidden") {
        document.documentElement.style.overflow = "auto";
      } else {
        document.documentElement.style.overflow = "hidden";
      }
      this.isActive = !this.isActive;
    },
    async handleLanguageChange(language) {
      this.closeClick();
      this.updateLangOrder(language.value);
      let lang = "";
      switch (language.value) {
        case "ZH-TW":
          this.$i18n.locale = "tw";
          lang = "tw";
          break;
        case "EN-US":
          this.$i18n.locale = "en";
          lang = "en";
          break;
        case "ZH-CN":
          this.$i18n.locale = "cn";
          lang = "cn";
          break;
      }
      if (this.isLogin && !this.getProfile.length) {
        await userApi.saveLang({
          lang: lang
        });
      }
      this.changeLang(language);
    },
    async autoLang() {
      this.currCountryCode = "EN";
      this.changeLangDefault();
    },
    changeLangDefault() {
      let language = {};
      if (this.currCountryCode === "TW") {
        this.$i18n.locale = "tw";
        language = this.languageList.find(x => x.value === "ZH-TW");
      } else if (this.currCountryCode === "CN") {
        this.$i18n.locale = "cn";
        language = this.languageList.find(x => x.value === "ZH-CN");
      } else {
        this.$i18n.locale = "en";
        language = this.languageList.find(x => x.value === "EN-US");
      }
      this.updateLangOrder(language.value);
      this.changeLang(language);
    },
    async handleLogout() {
      this.closeClick();
      await this.logout();

      await this.$message({
        message: this.$t("message.logout_success"),
        type: "success"
      });
      if (this.$route.name === "LandingPage") {
        return;
      }

      this.$router.push({
        name: "LandingPage"
      });
    },
    updateLangOrder(curLang) {
      this.languageList1 = [];
      this.languageList2 = [];
      const currIndex = this.languageList.findIndex(x => x.value === curLang);
      if (currIndex === 0) {
        this.languageList1.push(this.languageList[1]);
        this.languageList1.push(this.languageList[2]);
        this.languageList2.push(this.languageList[1]);
        this.languageList2.push(this.languageList[0]);
        this.languageList2.push(this.languageList[2]);
      } else if (currIndex === 1) {
        this.languageList1.push(this.languageList[0]);
        this.languageList1.push(this.languageList[2]);
        this.languageList2 = this.languageList;
      } else {
        this.languageList1.push(this.languageList[0]);
        this.languageList1.push(this.languageList[1]);
        this.languageList2.push(this.languageList[0]);
        this.languageList2.push(this.languageList[2]);
        this.languageList2.push(this.languageList[1]);
      }
    },
    closeMobileMenu(e) {
      if (e.target.screen.width > 992) {
        this.isActive = false;
      }
    },
    startObserveWindowWidth() {
      window.addEventListener("resize", this.closeMobileMenu);
    },
    toggleCollapse(collapse) {
      let workIsShow = { ...this.isShow };
      Object.keys(this.isShow).forEach(key => {
        workIsShow[key] = false;
      });
      this.isShow = {
        ...workIsShow,
        [collapse]: !this.isShow[collapse]
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.icon {
  font-size: 20px;
  height: 20px;
  line-height: 20px;
  margin-right: 5px;
}
.toefl-chevron-down {
  margin-left: 5px;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
  color: var(--themeColor);
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
}
.toefl-chevron-down:hover {
  background-color: var(--themeColor);
  color: white;
}
.card-header h2 small {
  color: #616161;
}
.card-header h2 {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.logo h2 {
  font-weight: 700;
  display: inline-block;
}
.toefl-r {
  font-weight: 700;
  font-size: 22px;
  display: inline-block;
  vertical-align: top;
  padding-top: 10px;
  margin-right: 5px;
}
.fb-like.fb_iframe_widget {
  bottom: 5px;
}
.header h2 {
  color: var(--themeColor);
  line-height: 60px;
  margin: 0;
}
.card-header h2 {
  line-height: 40px;
  margin: 0;
}
@import "@/assets/scss/navbar.scss";

.toefl-logo {
  padding-left: 20px;
  padding-right: 20px;
}
.topLineBeforeScroll {
  width: 100%;
  top: 0;
  z-index: 2000;
  background-color: #eeeeee;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.topLineAfterScroll {
  display: none;
}

.menuBeforeScroll {
  margin-top: 40px;
  border-bottom: 1px solid #e6e9ed;
}

.menuAfterScroll {
  margin-top: 0px;
}

@media screen and (max-width: 992px) {
  .topLineBeforeScroll {
    display: none;
  }
  .menuBeforeScroll {
    margin-top: 0px;
  }
}

.el-button {
  outline: none;
}

.menuPadding {
  padding-right: 15px;
}

::v-deep .userSection .el-submenu__title {
  padding-right: 0px;
  padding-left: 20px;
}

::v-deep .el-submenu__icon-arrow {
  margin-left: 2px !important;
}

.userButton {
  font-size: 16px;
}

.ivy-logo {
  width: 120px;
  height: 40px;
  margin: 10px 0;
  cursor: pointer;
}

.el-col {
  width: auto;
}

.el-menu {
  border-bottom: 0 !important;

  .el-menu-item {
    padding: 0 12px;
  }
}

::v-deep .el-dropdown i {
  color: #fff;
}

.onHover {
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
  font-size: 16px;
  font-weight: 700;
}
.onHover:hover {
  opacity: 0.6;
  transition: opacity 0.2s ease-in-out;
}
.headerMenuItemText {
  display: block !important;
}

.fade-enter-active {
  transition: opacity 0.8s;
}
.fade-leave-active {
  opacity: 0;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.listParent {
  width: 100%;
  height: 100%;
  display: block;
  /* text-align: center; */
}

.list {
  padding-top: 5px;
  height: 40px;
}

.list-active {
  color: var(--themeColor);
}

.listText {
  display: block;
  height: 100%;
  color: var(--themeColor);
  cursor: pointer;
}

.collapse-item {
  font-size: 20px;
  color: #616161;
  cursor: pointer;
}

.collapse-item:hover {
  opacity: 0.5;
}

.input-icon {
  width: 18px;
  height: 18px;
  margin-right: 3px;
  background-color: #212121;
  cursor: pointer;
}

.icon-email {
  mask: url("../assets/svg/email.svg") no-repeat center/contain;
}

.icon-mobile {
  mask: url("../assets/svg/cellphone-iphone.svg") no-repeat center/contain;
}

.icon-facebook {
  mask: url("../assets/svg/facebook.svg") no-repeat center/contain;
}

.icon-line {
  mask: url("../assets/svg/line-brands.svg") no-repeat center/contain;
}

.icon-wechat {
  mask: url("../assets/svg/wechat.svg") no-repeat center/contain;
}

::v-deep .menuTop .el-submenu__title {
  margin: 0px;
  padding: 0px;
  max-height: 30px;
}

::v-deep .menuTop .el-submenu__title p {
  margin: 0px;
  padding: 0px;
  max-height: 30px;
}

.langOnTop {
  color: #212121;
  opacity: 1;
}

.langOnTop:hover {
  color: var(--themeColor);
  opacity: 0.8;
  cursor: pointer;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.el-button--mini {
  padding: 5px 15px;
}

.el-button--default {
  padding: 0px;
  padding-bottom: 5px;
}

.card {
  border: 0px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 18px;
}

.card-header {
  background-color: white;
  border: 0px;
  padding-top: 0;
  padding-bottom: 0;
}

.menuItem {
  color: black !important;
}

.menuItem:hover {
  text-decoration: none !important;
  color: rgba(0, 0, 0, 0.4) !important;
}

.el-menu--horizontal > .el-menu-item {
  border-bottom: none;
}

.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: none;
}

::v-deep .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  border-bottom: none;
}
.select-button {
  padding: 5px 15px;
  font-size: 16px;
  .external-icon {
    display: none;
  }

  .external-text {
    display: inline;
  }
}
::v-deep .el-menu-item * {
  vertical-align: unset;
}

.proIcon {
  display: inline-block;
  margin: 0px 4px 4px 4px;
}

::v-deep .el-submenu__title {
  padding: 0px;
}

.subItem {
  font-weight: bold;
  color: var(--themeColor) !important;
}

.subItem:hover {
  color: #9dc4a9 !important;
}

.card-body .list {
  padding: 0 5px 0 35px;
  line-height: 40px;
}
.card-body .list:hover {
  background-color: #ecf6f0;
}
@media screen and (max-width: 1150px) {
  #menu > ul > li {
    padding: 0px 7px;
  }
  ::v-deep .el-drawer__header {
    margin-bottom: 15px;
  }

  ::v-deep .userSection .el-submenu__title {
    padding-left: 8px;
  }
  ::v-deep .card-header {
    padding: 5px 15px;
  }
  ::v-deep .card-body {
    padding: 0;
  }
  .select-button {
    .external-icon {
      display: inline-block;
      margin-right: 8px;
    }

    .external-text {
      display: none;
    }
  }
}
@media screen and (max-width: 736px) {
  .collapse-item {
    font-size: 16px;
    color: #616161;
    cursor: pointer;
  }
}
@media screen and (max-width: 400px) {
  .toefl-logo {
    padding-left: 15px;
    padding-right: 15px;
  }
  .header .card h2 {
    line-height: 30px;
  }
  ::v-deep .el-button {
    width: 100%;
  }
}
</style>
