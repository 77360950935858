import axios from "axios";

const state = {
  key: null,
  img: null
};

const getters = {
  key(state) {
    return state.key;
  },
  img(state) {
    return state.img;
  }
};

const actions = {
  getCaptcha(context) {
    axios.get(process.env.VUE_APP_BACKEND_URL + "captcha/flat").then(async data => {
      const { img, key } = data.data;

      await context.commit("setKey", key);
      await context.commit("setImg", img);
      console.log("API");
      console.log(key);
      console.log(img);

      return data;
    });
  }
};

const mutations = {
  setKey(state, data) {
    state.key = data;
  },
  setImg(state, data) {
    state.img = data;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
